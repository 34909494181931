import { ChangeEvent } from 'react';
import { TrackerState, loadTracker } from './state/Tracker';
import Settings from './permalink/Settings';
import Logic from './logic/Logic';
import { AppDispatch, RootState } from './state/Store';
import { loadLogic } from './state/Logic';
import { useDispatch, useSelector } from 'react-redux';

export interface ExportState {
    state: TrackerState;
    source: string;
}


async function importState(importedState: ExportState, dispatch: AppDispatch) {
    const source = importedState.source
    if (!source) {
        alert('invalid source');
    }
    const settings = new Settings();
    if (importedState.state.settings) {
        settings.loadFrom(importedState.state.settings);
    } else {
        await settings.init(source);
        importedState.state.settings = settings;
    }

    const logic = new Logic();
    await logic.initialize(settings, source);

    const state = importedState.state;

    dispatch(loadTracker(state));
    dispatch(loadLogic({ logic, options: settings.allOptions, source }));
}

export default function ImportExport() {
    const state = useSelector((state: RootState) => state.tracker);
    const source = useSelector((state: RootState) => state.logic.source!);
    const exportState = {
        state,
        source,
    };
    const dispatch = useDispatch();

    const doImport = (text: string) => importState(JSON.parse(text) as ExportState, dispatch);
    const doExport = () => {
        const filename = `SS-Rando-Tracker${Date()}`;
        const exportstring = JSON.stringify(exportState, undefined, '\t');
        const blob = new Blob([exportstring], { type: 'json' });
        const e = document.createEvent('MouseEvents'); const
            a = document.createElement('a');
        a.download = `${filename}.json`;
        a.href = window.URL.createObjectURL(blob);
        a.dataset.downloadurl = ['json', a.download, a.href].join(':');
        e.initEvent('click');
        a.dispatchEvent(e);
    };


    const readFile = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files?.length) {
            return;
        }
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {
            if (!e.target?.result) {
                return;
            }
            doImport(e.target.result.toString())
        };
    }

    return (
        <div id="ImportExport">
            <button type="button" onClick={doExport}>Export Tracker</button>
            <input id="fileInput" type="file" accept=".json" onChange={readFile} />
        </div>
    );
}
